<template>
  <div id="portfolio" class="container">
    <h1 class="d-none d-xl-block">
      Our <span>Portfolio</span>
    </h1>
    <h2 class="d-xl-none d-sm-block">
      Our <span>Portfolio</span>
    </h2>
    <div class="row mt-5">
      <div
        id="top-divider"
        class="col-12 mb-5"
      >
        <hr>
        <p class="subtitle pl-0">Projects that we truly believe in</p>
      </div>
      <div
        v-for="investment in getInvestments"
        :key="investment.id"
        class="col-6 col-lg-auto"
      >
        <b-card
          overlay
          :img-src="investment.investmentLogo.url"
          :img-alt="investment.investmentTitle"
          class="investment-card mb-3 rounded"
          @click="goToPage(investment.investmentTitle)"
          @mousedown.middle="goToPage(investment.investmentTitle)"
        >
          <div class="investment-card__metadata">
            <p class="collection my-0">
              {{investment.investmentTitle}}
            </p>
          </div>
        </b-card>
      </div>
      <div
        id="bottom-divider"
        class="col-12 mt-5"
      >
        <hr>
        <p class="subtitle">Projects that we truly believe in</p>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'

export default {
  name: 'Portfolio',
  computed: {
    ...mapGetters(['getInvestments'])
  },
  methods: {
    ...mapActions(['clearInvestment', 'queryInvestments', 'createTimestamp']),
    goToPage(title) {
      this.$router.push({name: 'Investment', params: {investment: title}})
    }
  },
  mounted () {
    this.clearInvestment()
    this.createTimestamp('investments')
    this.queryInvestments()
  }
}
</script>

<style scoped lang="scss">
@import '../../styles/_variables.scss';

#portfolio {
  margin-top: 83px;

  h1 {
    font-size: 5rem;
    line-height: 5rem;
  }
  h2 {
    font-size: 2rem;
    line-height: 2rem;
  }

  h1, h2 {
    font-family: 'Oswald', sans-serif;
    text-transform: uppercase;
    font-weight: normal;
    padding-left: 19px;
    border-left: 5px solid $regular-pink;

    span {
      color: $regular-pink;
    }
  }

  hr {
    background-color: $regular-pink;
  }

  .subtitle {
    font-size: 1rem;
    width: auto;
    position: absolute;
    top: .25rem;
    background-color: $dark-grey;
    padding-left: 1rem;
    padding-right: 1rem;
    font-family: 'Anonymous Pro', monospace;
  }

  #bottom-divider {
    .subtitle {
      right: 0;
    }
  }

  .investment-card {
    border: 4px solid $regular-pink;
    background-color: $regular-pink;
    overflow: hidden;
    cursor: pointer;
    max-width: 12.2rem;

    &:hover {
      border-color: $light-pink;

      .investment-card__metadata {
        background-color: $light-pink;
      }
    }

    &__metadata {
      background-color: $regular-pink;
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 6px 12px;

      .collection {
        font-family: 'Anonymous Pro', monospace;
        font-size: 1.2rem;
      }
    }
  }
}

</style>